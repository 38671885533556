import Http from '@/services/Http'
import BaseService from '@/services/BaseService'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import httpBuildQuery from 'http-build-query'

dayjs.extend(customParseFormat)
const urlAPI = process.env.VUE_APP_APIENDPOINT

export const TradeLaneService = {
  /**
   * http://localhost/api/V1/customer
   *
   * @param filter
   * @returns {Promise<any>}
   */
  getList (filter) {
    let queryString = httpBuildQuery(filter)

    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/trade-lane?${queryString}`, {}).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  postImportFile (content, formData) {
    try {
      let queryString = httpBuildQuery(formData)
      // console.log('content', content)
      return new Promise(function (resolve, reject) {
        Http.post(`${urlAPI}v2/indice-fila-importacao?${queryString}`, content).then(function (res) {
          if (BaseService.validateResponseReturn(res)) {
            // console.log('postImportFile res: ', res)
            resolve({ data: res.data })
          }
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(new Error('formulário invalido'))
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  },
  getImportedFile (filename) {
    let queryString = httpBuildQuery({
      fileName: filename
    })
    try {
      return new Promise(function (resolve, reject) {
        Http.get(`${urlAPI}v2/indice-fila-importacao/file?${queryString}`, { responseType: 'blob' }).then(function (res) {
          resolve({ data: res.data })
        }).catch(function (err) {
          BaseService.checkSesssionExpired(err)
          reject(err)
        })
      })
    } catch (err) {
      BaseService.showMessageError(err)
    }
  }
}

export default TradeLaneService
